import React from 'react'
import { Fade, Modal } from "@mui/material";
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import arrowLeftBlack from "@assets/icons/leftarr.svg"
import people from "@assets/icons/people.svg"
import arrowDown from "@assets/icons/arrow-down.svg"
import StrongFlySm from './StrongFlySm';
import PassengerDropdown from './PassengerDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: '0px -24px 24px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "90vh",
    overflowY: "scroll",
    transition: '1s'
};

function StrongFlyModal(
    {
        open3, handleClose3,
        handleOpen2, strongFlyNum,
        fromCodeErr, setAirlinesDataFrom,
        setMyAirlinesCode, airlinesDataTo,
        myAirlinesDateInpErr, dateCodeErr,
        searchAirportsFnc, clearSearchLine,
        setFormData, setStrongFlyNum,
        strongFlyArr, setClassModalShow,
        classModalShow, ticketAdults,
        ticketChild, ticketBabies,
        airlinesDataFrom, toCodeErr,
        setAirlinesDataTo, formData,
        searchAirportsToFnc, setStrongFlyArr,
        getPassengerText, totalPassengers,
        ticketTarif, setTicketAdult,
        setTicketChild, setTicketBabies,
        setIsBaggage, isBaggage,
        getRecommendationDifficult, strongFly, setticketTarif,
        setFromCodeErr, setToCodeErr, setDateCodeErr
    }
) {

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const lang = useSelector(state => state.AllSlice.lang)

    // const handleSearchStrongFly = () => {
    //     const jsonString = JSON.stringify(formData);

    //     const base64 = btoa(encodeURIComponent(jsonString));

    //     navigate(`/search/${base64}!!![${strongFlyArr}]`);
    // };

    function base64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    }

    const handleSearchStrongFly = () => {
        const allFlyDirection = strongFlyArr && strongFlyArr.map((item) => ({
            from: formData[`fromCode${item}`],
            to: formData[`toCode${item}`],
            date: formData[`date${item}`],
        }));

        const isValid = allFlyDirection.every((flight, index) => {
            const isFieldValidFrom = flight.from
            const isFieldValidTo = flight.to
            const isFieldValidDate = flight.date
            if (!isFieldValidFrom) {
                setFromCodeErr(index)
                // setToCodeErr(index)
                return false;
            }
            else if (!isFieldValidTo) {
                setToCodeErr(index)
                return false;
            }
            else if (!isFieldValidDate) {
                setDateCodeErr(index)
                return false;
            }
            return true;
        });

        if (!isValid) {
            console.warn("Some fields are either missing or incorrectly named.");
            return;
        }

        const jsonString = JSON.stringify(formData);
        const base64 = base64EncodeUnicode(jsonString);

        const url = `/search/${base64}!!![${strongFlyArr}]!!!${ticketAdults}!!!${ticketChild}!!!${ticketBabies}!!!${ticketTarif}`;

        navigate(url);
    }

    return (
        <Modal
            open={open3}
            onClose={handleClose3}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{ overflowY: "scroll" }}
            closeAfterTransition
            BackdropProps={{
                timeout: 1500,
            }}
        >
            <Fade in={open3}>
                <Box sx={style}>
                    <p onClick={() => { handleClose3(); handleOpen2() }} className="flex cursor-pointer">
                        <img loading="lazy" className="mr-[5px]" src={arrowLeftBlack} alt="" />
                        {t("search")}
                    </p>
                    <h1 className="text-2xl font-bold text-center mt-[50px]">
                        {t("dificultFly")}
                    </h1>
                    <div className="mt-[64px]">
                        {[...Array(strongFlyNum)].map((item, inx) =>
                            <StrongFlySm
                                key={inx}
                                fromCodeErr={fromCodeErr}
                                setAirlinesDataFrom={setAirlinesDataFrom}
                                setMyAirlinesCode={setMyAirlinesCode}
                                airlinesDataFrom={airlinesDataFrom}
                                toCodeErr={toCodeErr}
                                setAirlinesDataTo={setAirlinesDataTo}
                                airlinesDataTo={airlinesDataTo}
                                myAirlinesDateInpErr={myAirlinesDateInpErr}
                                dateCodeErr={dateCodeErr}
                                searchAirportsFnc={searchAirportsFnc}
                                formData={formData}
                                searchAirportsToFnc={searchAirportsToFnc}
                                clearSearchLine={clearSearchLine}
                                inx={inx}
                                setFormData={setFormData}
                            />
                        )}
                        <p onClick={() => { dispatch(setStrongFlyNum(strongFlyNum + 1)); dispatch(setStrongFlyArr([...strongFlyArr, strongFlyNum + 1])) }} className="text-[#2E5B8B] text-lg font-semibold text-center mt-[32px]">+
                            {
                                lang == 'ru' && "Добавить перелет"
                            }
                            {
                                lang == 'uz' && "Boshqa parvozni qo'shish"
                            }
                            {
                                lang == 'en' && "Add another flight"
                            }
                        </p>
                    </div>
                    {/* <div className='h-[60px] mt-[15px] rounded-lg border border-[#c0bfbf] relative outline-none px-[10px] w-full  flex items-center justify-between' >
                        <div className="flex items-center justify-between w-full h-full" onClick={() => setClassModalShow(!classModalShow)}>
                            <div className="flex items-center">
                                <img loading="lazy" className="mr-[10px]" src={people} alt="" />
                                <div>
                                    <h3>{ticketAdults + ticketChild + ticketBabies} {getPassengerText(totalPassengers)}</h3>
                                    <p className='text-[#AEAEAE] font-normal'>
                                        {ticketTarif == 'b' && t("classBusiness")}
                                        {ticketTarif == 'e' && t("ekanomClass")}
                                        {ticketTarif == 'f' && t("firstClass")}
                                        {ticketTarif == 'w' && t("comfortClass")}
                                        {ticketTarif == 'a' && t("allCalss")}
                                    </p>
                                </div>
                            </div>
                            <img loading="lazy" onClick={() => setClassModalShow(!classModalShow)} className="" src={arrowDown} alt="" />
                        </div>
                        {classModalShow && (
                           
                        )}
                    </div> */}
                    <PassengerDropdown
                        setClassModalShow={setClassModalShow} classModalShow={classModalShow}
                        totalPassengers={totalPassengers} getPassengerText={getPassengerText}
                        ticketTarif={ticketTarif} ticketAdults={ticketAdults}
                        ticketChild={ticketChild} ticketBabies={ticketBabies}
                        setTicketAdult={setTicketAdult} setTicketChild={setTicketChild}
                        setTicketBabies={setTicketBabies} setIsBaggage={setIsBaggage}
                        isBaggage={isBaggage} strongFly={strongFly} setticketTarif={setticketTarif}
                    />

                    <button className="bg-[#2E5B8B] text-[white] mt-[8px] text-lg rounded-lg py-[18px] w-full" onClick={handleSearchStrongFly}> {t("searchTicket")} </button>
                </Box >
            </Fade >
        </Modal >
    )
}

export default StrongFlyModal