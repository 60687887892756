import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Weather.css'; // Include your custom CSS here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFrown } from '@fortawesome/free-solid-svg-icons';
import { useSearchAirportsMutation } from '@services/AllApi';
import { useSelector } from 'react-redux';

function WeatherContainer({ type, city, day, grade, active }) {
    const defaultType = "Unknown";
    const knownTypes = ["Clear", "Rain", "Clouds", "cloud", "Thunderstorm", "Haze", "Dust"];
    const weatherType = knownTypes.includes(type) ? type : defaultType;

    return (
        <div className={`container-${weatherType} mt-[18px] w-full ${active ? 'active' : ''}`}>
            <div className={`box-shadow-${weatherType}`}></div>
            <div className={`content-${weatherType} py-[20px]`}>
                <div className={`image-${weatherType}`}>
                    {weatherType === "Clear" && <div className="Clear"></div>}
                    {weatherType === 'Rain' && (
                        <div className="rain-cloud">
                            <div className="raindrop"></div>
                            <div className="raindrop"></div>
                            <div className="raindrop"></div>
                        </div>
                    )}
                    {weatherType === 'Clouds' && (
                        <>
                            <div className="sun-inner"></div>
                            <div className="cloud-sun-inner"></div>
                        </>
                    )}
                    {weatherType === 'cloud' && <div className="cloud"></div>}
                    {weatherType === 'Thunderstorm' && (
                        <div className="cloud-storm">
                            <div className="raindrop-storm"></div>
                            <div className="raindrop-storm"></div>
                            <div className="raindrop-storm"></div>
                            <div className="bolt"></div>
                        </div>
                    )}
                    {weatherType === 'Haze' && <div className="haze"></div>}
                    {weatherType === 'Dust' && <div className="dust-particle"></div>}
                </div>
                <div className={`text-${weatherType}`}>
                    <p className="day whitespace-nowrap">{day}</p>
                    <p className="city">{city}</p>
                    <p className="grade">{grade}</p>
                </div>
            </div>
        </div>
    );
}

function Weather({ weatherCity }) {
    const [searchAirports, { data: searchAirportsFrom, isSuccess: searchAirportsFromSuc }] = useSearchAirportsMutation();
    const lang = useSelector(state => state.AllSlice.lang);

    const [weather, setWeather] = useState({
        loading: false,
        data: {},
        error: false,
    });

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const searchData = {
            lang: lang,
            part: weatherCity,
        };
        searchAirports(searchData);
    }, [weatherCity, lang, searchAirports]);

    useEffect(() => {
        if (!searchAirportsFromSuc || !searchAirportsFrom?.data?.cities?.[weatherCity]) {
            setWeather({ ...weather, error: true });
            return;
        }

        const cityName = searchAirportsFrom.data.cities[weatherCity]?.cityName || weatherCity;

        setWeather({ ...weather, loading: true });
        const url = 'https://api.openweathermap.org/data/2.5/weather';
        const api_key = 'f00c38e0279b7bc85480c3fe775d518c';

        axios
            .get(url, {
                params: {
                    q: cityName,
                    units: 'metric',
                    appid: api_key,
                },
            })
            .then((res) => {
                setWeather({ data: res.data, loading: false, error: false });
            })
            .catch((error) => {
                console.error('Error fetching weather data:', error);
                setWeather({ ...weather, data: {}, error: true });
            });
    }, [searchAirportsFromSuc, searchAirportsFrom, weatherCity]);

    const formatDate = (dateString) => {
        if (!dateString) return 'Invalid Date';
        const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Мая', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
        const weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        const [day, month, year] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);

        return `${date.getDate()} ${months[date.getMonth()]}, ${weekdays[date.getDay()]}`;
    };

    const getFormattedDateString = () => {
        const date = new Date();
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    };

    const weatherDataArray = [
        {
            type: weather.data.weather?.[0]?.main || "Unknown",
            city: weather.data.name || 'Unknown City',
            day: formatDate(getFormattedDateString()),
            grade: weather.data.main ? `${weather.data.main.temp}°C` : 'N/A',
        },
    ];

    const prevContainer = () => setCurrentIndex((currentIndex - 1 + weatherDataArray.length) % weatherDataArray.length);
    const nextContainer = () => setCurrentIndex((currentIndex + 1) % weatherDataArray.length);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') prevContainer();
            if (event.key === 'ArrowRight') nextContainer();
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [currentIndex, weatherDataArray.length]);

    return (
        <div className="slider-container">
            {/* {weather.error && (
                <div className="error">
                    <FontAwesomeIcon icon={faFrown} />
                    <span>Город не найден</span>
                </div>
            )} */}
            {weather.data?.main && weatherDataArray.map((data, index) => (
                <WeatherContainer key={index} {...data} active={index === currentIndex} />
            ))}
        </div>
    );
}

export default Weather;
