import React, { useEffect, useRef, useState } from 'react'
import Flights from '@components/Main/Flights'
import Ticket from '../../components/Tickets/Ticket'
import { useDispatch, useSelector } from 'react-redux';
import { setStrongFly, setStrongFlyArr, setStrongFlyNum, setActiveIndex, setDatePickerBack } from '@Slice/HomeSlice';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import SearchTabs from './SearchTabs';

function Search() {
    const lang = useSelector((state) => state.AllSlice.lang)
    const ticketAdults = useSelector((state) => state.HomeSlice.ticketAdults);
    const ticketChild = useSelector((state) => state.HomeSlice.ticketChild);
    const ticketBabies = useSelector((state) => state.HomeSlice.ticketBabies);
    const ticketTarif = useSelector((state) => state.HomeSlice.ticketTarif);
    const getRecommendationSuc = useSelector((state) => state.HomeSlice.getRecommendationSuc);
    const loadingGetRecommendation = useSelector((state) => state.HomeSlice.loadingGetRecommendation);
    const getRecommendationData = useSelector((state) => state.HomeSlice.getRecommendationData);
    const getRecommendationErr = useSelector((state) => state.HomeSlice.getRecommendationErr);
    const strongFly = useSelector((state) => state.HomeSlice.strongFly);
    const strongFlyNum = useSelector((state) => state.HomeSlice.strongFlyNum);
    const strongFlyArr = useSelector((state) => state.HomeSlice.strongFlyArr);

    const [loader, setLoader] = useState(true);
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    // const [dateHotelPicker2, setDateHotelPicker2] = useState('');
    const [isTicketPagination, setIsTicketPagination] = useState(true)


    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setWindowWidth(window.innerWidth)
        })
    }, [])


    const totalPassengers = ticketAdults + ticketChild + ticketBabies;

    const getPassengerText = (count) => {
        if (lang == "ru") {
            if (count > 1 && count < 5) {
                return 'Пассажира';
            } else if (count >= 5) {
                return 'Пассажиров';
            } else {
                return 'Пассажир';
            }
        }
        if (lang == 'uz') {
            return "Yo'lovchi"
        }
        if (lang == 'en') {
            if (count == 1) {
                return 'passenger'
            }
            else {
                return 'passengers'
            }
        }
    };

    useEffect(() => {
        dispatch(setActiveIndex(0))
    }, [])


    const parts = params.id?.split("&") || '';
    const partsStrongFly = params?.id?.split("!!!");







    return (
        <div>
            <Helmet>
                <title> My Safar - Search </title>
                <meta name="description" content="Explore the Search Page of My Safar App" />

            </Helmet>

            <div className="container mx-auto mt-6">
                <Flights version={'inCanvas'} parts={parts} setStrongFly={setStrongFly} strongFly={strongFly} setStrongFlyArr={setStrongFlyArr} strongFlyArr={strongFlyArr} setLoader={setLoader}
                    totalPassengers={totalPassengers} getPassengerText={getPassengerText}
                    isTicketPagination={isTicketPagination} setIsTicketPagination={setIsTicketPagination} setStrongFlyNum={setStrongFlyNum} strongFlyNum={strongFlyNum} />
            </div>
            <Ticket getrecTicketSuc={getRecommendationData?.success} windowWidth={windowWidth} allPassengrs={totalPassengers} strongFly={strongFly} reSuc={getRecommendationSuc} reErr={getRecommendationErr} loading={loadingGetRecommendation} checkPagination={isTicketPagination} dateTo={parts?.length === 9 && `${parts[3]}`} weatherCity={`${parts[1]}`} weatherDate={`${parts[2]}`} myAirlines={`${parts[0]}`} passengers={parts?.length >= 9 ? (parseInt(parts[4]) + parseInt(parts[5]) + parseInt(parts[6])) : partsStrongFly?.length === 8 && (parseInt(partsStrongFly[3]) + parseInt(partsStrongFly[4]) + parseInt(partsStrongFly[5]))} ticketTarif={parts?.length >= 9 ? parts[7] : parts?.length === 8 && parts[6]} />
            <div id='show_moreEl'></div>
        </div>
    )
}

export default Search